import React, { useState } from 'react';
import axios from 'axios';   
import './styles/upload.css';

const genres = [
    'Rap',
    'Rock',
    'Pop',
    'Hip Hop',
    'R&B',
    'Country',
    'Jazz',
    'Classical',
    'Electronic',
    'Folk',
    'Other',
];

const Upload = () => {
    const [songName, setSongName] = useState('');
    const [artistName, setArtistName] = useState(''); // New state for artist name
    const [coverArt, setCoverArt] = useState(null);
    const [genre, setGenre] = useState('');
    const [bio, setBio] = useState('');
    const [audioFile, setAudioFile] = useState(null);

    const [coverArtName, setCoverArtName] = useState(' no file selected');
    const [audioFileName, setAudioFileName] = useState(' no file selected');

    const [coverArtPreview, setCoverArtPreview] = useState(null);
    const [uploading, setUploading] = useState(false);

    const handleSongNameChange = (event) => setSongName(event.target.value);
    const handleArtistNameChange = (event) => setArtistName(event.target.value); // New handler for artist name
    const handleBioChange = (event) => setBio(event.target.value);

    const handleCoverArtChange = (event) => {
        const file = event.target.files[0];
        setCoverArt(file);
        setCoverArtName(file ? file.name : ' no file selected');

        const reader = new FileReader();
        reader.onload = (e) => {
            setCoverArtPreview(e.target.result);
        };
        reader.readAsDataURL(file);
    };

    const handleAudioFileChange = (event) => {
        const file = event.target.files[0];
        setAudioFile(file);
        setAudioFileName(file ? file.name : ' no file selected');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setUploading(true);
        const formData = new FormData();
        formData.append('songName', songName);
        formData.append('artistName', artistName); // Include artist name in form data
        formData.append('genre', genre);
        formData.append('bio', bio);
        formData.append('coverArt', coverArt);
        formData.append('audioFile', audioFile);

        try {
            const response = await fetch('https://api.lofted.io/api/upload-song', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            setUploading(false);
            if (data.success) {
                alert('Upload successful');
                setSongName('');
                setArtistName(''); // Reset artist name
                setCoverArt(null);
                setGenre('');
                setBio('');
                setAudioFile(null);
                setCoverArtName(' no file selected');
                setAudioFileName(' no file selected');
            } else {
                alert('Upload failed');
            }
        } catch (error) {
            console.error('Error:', error);
            setUploading(false);
            alert('Upload failed');
        }
    };

    return (
        <div className="upload-page">
            {uploading && (
                <div className="overlay">
                    <div className="loading-text">Uploading, please don't close this window...</div>
                </div>
            )}
            <div className="upload-container">
                <form className="upload-form" onSubmit={handleSubmit}>
                    <h1 className="form-title">Upload Music To Lofted</h1>
                    <input
                        className="text-input"
                        type="text"
                        placeholder="Song Name"
                        value={songName}
                        onChange={handleSongNameChange}
                        required
                    />
                    <input
                        className="text-input"
                        type="text"
                        placeholder="Artist Name" // New input for artist name
                        value={artistName}
                        onChange={handleArtistNameChange}
                        required
                    />
                    <div className="file-input-container">
                        <label htmlFor="cover-art" className="file-input-label">Upload Cover Art:</label>
                        <input
                            id="cover-art"
                            className="file-input"
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleCoverArtChange}
                            required
                            hidden
                        />
                        <span className="file-name">{coverArtName}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="genre-select">Genre:</label>
                        <select
                            id="genre-select"
                            className="genre-select"
                            value={genre}
                            onChange={(e) => setGenre(e.target.value)}
                            required
                        >
                            <option value="">--Please choose an option--</option>
                            {genres.map((genre, index) => (
                                <option key={index} value={genre}>{genre}</option>
                            ))}
                        </select>
                    </div>
                    <textarea
                        className="textarea-input"
                        placeholder="Add Bio - give info regarding song"
                        value={bio}
                        onChange={handleBioChange}
                        required
                    />
                    <div className="file-input-container">
                        <label htmlFor="audio-file" className="file-input-label">Upload File - MP3 or Flac:</label>
                        <input
                            id="audio-file"
                            className="file-input"
                            type="file"
                            accept=".mp3,.flac"
                            onChange={handleAudioFileChange}
                            required
                            hidden
                        />
                        <span className="file-name">{audioFileName}</span>
                    </div>
                    <button className="submit-button" type="submit">UPLOAD TO LOFTED</button>
                </form>
            </div>
            <div className="preview-container">
                <h2 className="preview-title">In App Appearance</h2>
                <div className="phone-container">
                    <div className="album-art-container">
                        {coverArtPreview && (
                            <div className="album-art-preview">
                                <img src={coverArtPreview} alt="Album Art Preview" className="cover-art-image"/>
                                <div className="song-info-overlay">
                                    <h4 className="overlay-artist-name">{artistName || 'Artist Name'}</h4> {/* Display artist name */}
                                    <h3 className="overlay-song-name">{songName || 'Song Name'}</h3>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="artist-profile-preview">
                        <img src="/assets/images/default-avatar.png" alt="Artist Avatar" className="artist-avatar"/>
                        <span className="artist-name-preview">{artistName || 'Artist Name'}</span> {/* Display artist name */}
                    </div>
                    <div className="artist-bio-box">
                        <p className="artist-bio">{bio || 'ARTIST BIO'}</p>
                    </div>
                </div>
            </div>
            <style>{`
                .overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    z-index: 9999;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .loading-text {
                    color: white;
                    font-size: 20px;
                    font-weight: bold;
                }
            `}</style>
        </div>
    );
};

export default Upload;

