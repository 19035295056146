import React, { useState } from 'react';
import axios from 'axios';

const Home = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleEmailChange = (event) => setEmail(event.target.value);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.lofted.io/api/join-waitlist', { email });
            if (response.data.success) {
                setMessage('Successfully joined the waitlist!');
                setEmail('');
            } else {
                setMessage('This email is already on the waitlist.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Failed to join the waitlist.');
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.logoContainer}>
                <img src="/loftedLogoTop1.png" alt="Lofted Logo" style={styles.logoImage} />
            </div>
            <video autoPlay loop muted playsInline style={styles.backgroundVideo}>
                <source src="https://lofted.io/background.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div style={styles.overlay}>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                        style={styles.input}
                    />
                    <button type="submit" style={styles.button}>Join Waitlist</button>
                </form>
                {message && <p style={styles.message}>{message}</p>}
            </div>
        </div>
    );
};

const styles = {
    container: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        textAlign: 'center',
    },
    logoContainer: {
        position: 'absolute',
        top: '20px', // Adjust as needed
        left: '20px',
	width: '400px',
	maxWidth: '400px',
        textAlign: 'left',
        zIndex: 2, // Ensure logo is visible above video
    },
    logoImage: {
        maxWidth: '400px', // Adjust the size of the logo as needed
        width: '100%',
    },
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: 0,
	display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    overlay: {
        position: 'relative',
        zIndex: 1,
        padding: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '10px',
        maxWidth: '500px',
        width: '80%',
        margin: 'auto',
        marginTop: '240px', // Move the box down further
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
	justifyContent: 'center',
    },
    input: {
        padding: '10px',
        fontSize: '1em',
        borderRadius: '5px',
        border: 'none',
        marginBottom: '10px',
        width: '100%',
    },
    button: {
        padding: '10px 20px',
        fontSize: '1em',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#808080', // Change button color to grey
        color: '#fff',
        cursor: 'pointer',
        width: '100%',
    },
    message: {
        marginTop: '10px',
        fontSize: '1em',
    },
};

export default Home;

